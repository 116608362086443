import SvgCheck from '@sats-group/icons/24/check';
import LinkButton from '@sats-group/ui-lib/react/link-button';
import Text from '@sats-group/ui-lib/react/text';
import React from 'react';

import type { NoMembership as Props } from './no-membership.types';

const NoMembership: React.FC<Props> = ({ link, title, usps }) => (
  <div className="no-membership">
    <div className="no-membership__content">
      <div>
        <Text
          elementName="h2"
          size={Text.sizes.basic}
          theme={Text.themes.emphasis}
        >
          {title}
        </Text>
        {usps.length ? (
          <ul className="no-membership__usps">
            {usps.map(entry => (
              <li className="no-membership__usp" key={entry}>
                <SvgCheck />
                <Text size={Text.sizes.basic}>{entry}</Text>
              </li>
            ))}
          </ul>
        ) : null}
        <div className="no-membership__link">
          <LinkButton {...link} variant={LinkButton.variants.cta} />
        </div>
      </div>
      <div className="no-membership__illustration" />
    </div>
  </div>
);

export default NoMembership;
