import Check from '@sats-group/icons/16/check';
import React from 'react';

import Text from '@sats-group/ui-lib/react/text';

import Price from 'components/price/price';

import type { RadioCard as Props } from './radio-card.types';

const RadioCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  details,
  detailsList,
  label,
  name,
  onClick = () => {},
  price,
  required,
  value,
  isChecked,
  children,
  visualOnly,
}) => (
  <div className="radio-card">
    {React.createElement(
      visualOnly ? 'div' : 'label',
      { className: 'radio-card__label' },
      <React.Fragment>
        {visualOnly ? null : (
          <input
            checked={isChecked}
            className="radio-card__radio-input"
            data-test-radio-card
            id={label}
            name={name}
            onChange={onClick}
            required={required}
            type="radio"
            value={value}
          />
        )}
        <div
          className={
            visualOnly ? 'radio-card__visual-check' : 'radio-card__check'
          }
        >
          <Check />
        </div>
        {label}
      </React.Fragment>
    )}

    {price ? (
      <div className="radio-card__price">
        <Price {...price} />
      </div>
    ) : null}

    {children ? (
      <div className="radio-card__children-container">{children}</div>
    ) : null}

    {details ? (
      <Text className="radio-card__details" size={Text.sizes.small}>
        {details}
      </Text>
    ) : null}
    {detailsList ? (
      <div className="radio-card__detailsList">
        {detailsList.map(detail => (
          <Text key={detail} size={Text.sizes.small}>
            {detail}
          </Text>
        ))}
      </div>
    ) : null}
  </div>
);

export default RadioCard;
